import React from "react"
import styled from "styled-components"
import SectionHeader from "components/SectionHeader"
import { graphql, Link, StaticQuery } from "gatsby"
import { translate } from "services/translate"
import Button from "components/Button"
import Loader from "components/Loader"
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form"
import TextInput from "components/Forms/TextInput"
import InstallPopup from "components/InstallPopup"
import ExternalAuth from "../ExternalAuth";

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  padding: 15px 15px 90px 15px;
  display: flex;
  flex-direction: column;
  background: url(${({ background }) => (background ? background : "")})
    no-repeat -470px top fixed;
  background-size: cover; 
  justify-content: space-around;
  position: relative;

  &:before {
    background: -webkit-linear-gradient(bottom,rgba(0,0,0,1) 0%,rgba(0,0,0,0.1) 100%);
    position: absolute;
    width: 100%;
    min-height: 100vh;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
  }
`

const FormWrapper = styled.div`
  margin: 3rem 0;
  position: relative;

  .input-wrapper {
    margin: 2rem 0;
  }

  .error {
    text-align: center;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`

const HintLink = styled.div`
    margin: 2rem 0;
    font-size: 1.4rem;
    text-align: center;
    font-weight: bold;
    position: relative;

    a {
      text-decoration: underline;
      color: white;
    }
`

class SignInScreen extends React.Component {
    loginForm = FormBuilder.group({
        email: ["", [Validators.required, Validators.email]],
        password: ["", Validators.required],
    })

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: "",
        }
    }

    handleStateWithExternalAuth = (newState) => {
        this.setState(newState);
    };

    handleSubmit = event => {
        const self = this
        event.preventDefault()

        const firebase = require("firebase")

        this.setState({
            loading: true,
        })

        firebase.auth().signInWithEmailAndPassword(this.loginForm.value.email, this.loginForm.value.password)
            .then((result) => {

                let loginMethod = false

                if (result.additionalUserInfo && result.additionalUserInfo.isNewUser) {
                    loginMethod = "?firstLoginEmail"
                }

                sessionStorage.setItem("firstUser", loginMethod)

                self.setState({
                    error: {},
                    loading: false,
                })
            })
            .catch(function(error) {
                // Handle Errors here.
                self.setState({
                    error: {
                        code: error.code,
                        message: translate(error.code),
                    },
                    loading: false,
                })
            })
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                {
                    loginBackground: file(relativePath: { eq: "login.jpg" }) {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                }
                `}
                render={data => (
                    <Wrapper background={data.loginBackground.childImageSharp.fluid.src}>
                        {this.state.loading &&
                        <Loader/>
                        }

                        <SectionHeader header="Zaloguj się" center="true"/>
                        <FormWrapper>
                            <FieldGroup
                                control={this.loginForm}
                                render={({ get, invalid }) => (
                                    <form onSubmit={this.handleSubmit}>

                                        <FieldControl
                                            name="email"
                                            render={TextInput}
                                            meta={{ label: "Adres e-mail", style: "bordered" }}
                                        />

                                        <FieldControl
                                            name="password"
                                            render={TextInput}
                                            meta={{
                                                label: "Hasło",
                                                type: "password",
                                                style: "bordered",
                                            }}
                                        />

                                        <HintLink>
                                            Zapomniałeś hasła? <Link to="/remind">Przypomnij hasło</Link>
                                        </HintLink>

                                        <Button
                                            type="submit"
                                            disabled={invalid}
                                            title="Zaloguj się"
                                        />
                                    </form>
                                )}
                            />

                            {this.state.error &&
                            <div className="error">
                                <div>{this.state.error.message}</div>
                            </div>
                            }
                        </FormWrapper>
                        <div>
                            <ExternalAuth onLoadFunc={this.handleStateWithExternalAuth} />
                            <HintLink>
                                Nie masz jeszcze konta? <Link to="/register">Zarejestruj się</Link>
                            </HintLink>
                        </div>
                        <InstallPopup/>
                    </Wrapper>
                )}
            />
        )
    }
}

export default SignInScreen
