import React from "react"
import SignInScreen from "./SignInScreen"
import { navigateWithParam } from "../../services/utils"
import { navigate } from "gatsby-link"

class Login extends React.Component {
    render() {

        if (this.props.user) {
            window.setTimeout(() => {
                let firstUser = sessionStorage.getItem("firstUser")
                if (firstUser === 'false') {
                    navigate(`/app/dashboard/`)
                } else {
                    navigateWithParam("/app/dashboard/", firstUser)
                }
            }, 300)
        }
        return (
            <SignInScreen
                auth={this.props.auth}
            />
        )
    }
}

export default Login
