import React from 'react';
import {graphql, StaticQuery} from "gatsby";
import styled from 'styled-components';
import Button from "components/Button"
import {getMobileOperatingSystem} from "services/utils";

const PopupWrapper = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    z-index: 1000;
    justify-content: center;
    align-items: center;
`;

const PopupContent = styled.div`
    width: 90%;
    background: white;
    border-radius: 15px;
    color: black;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    text-align: center;
`;

const PopupImage = styled.div`
    background: url(${({background}) => (background ? background : '')})
      no-repeat;
    min-height: 20vh;
    background-position: center top;
    background-size: contain;
    margin-top: 2rem;
`;

const PopupHeader = styled.div`
    font-size: 2rem;
    font-weight: bold;
    padding: 0 15px;
    margin-top: 2rem;
`;

const PopupText = styled.div`
    padding: 0 15px;
    margin-top: 2rem;
`;

const PopupInstruction = styled.div`
    margin: 2rem 0 0 0;
    padding: 0 15px;
`;

const PopupButtons = styled.div`
`;

const ClosePopupLink = styled.div`
    margin-top: 2rem;
    margin-bottom: ${({margin}) => (margin ? margin + 'rem' : '0')};
    padding: 0 15px;
    text-align: ${({align}) => (align ? align : 'center')};
    font-size: ${({small}) => (small ? '1.3rem' : 'inherit')};
`;

const Icon = styled.img`
    margin: 0 1rem;
`;

class InstallPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpened: false,
        };

        this.browser = "";
    }

    componentDidMount() {
        this.checkIfCanOpenPopup();
        this.browser = getMobileOperatingSystem();
    }

    checkIfCanOpenPopup = () => {
        let dontShowInstallPopup = localStorage.getItem('dontShowInstallPopup') === 'true';

        if (!dontShowInstallPopup) {
            this.setState({
                isOpened: true,
            })
        }
    };

    handleClosePopup = () => {
        this.setState({
            isOpened: false,
        });
    };

    handleCloseAndForget = () => {
        localStorage.setItem('dontShowInstallPopup', true);
        this.setState({
            isOpened: false,
        });
    };

    render() {
        return (
            <StaticQuery
                query={graphql`
                {
                    loginBackground: file(relativePath: { eq: "app_grid.png" }) {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    iosShareIcon: file(relativePath: { eq: "share.png" }) {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    menuIcon: file(relativePath: { eq: "menu.png" }) {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                }
                `}
                render={data => {
                    if (this.state.isOpened) {
                        return (
                            <PopupWrapper>
                                <PopupContent>
                                    <PopupImage background={data.loginBackground.childImageSharp.fluid.src}/>
                                    <PopupHeader>
                                        Zainstaluj BAR IS US
                                    </PopupHeader>
                                    <PopupText>
                                        Dodaj aplikację do głównego ekranu Twojego telefonu i ciesz się wszystkimi
                                        funkcjami aplikacji.
                                    </PopupText>
                                    {this.browser === 'iOS' &&
                                    <PopupInstruction>
                                        <div>
                                            Aby zainstalować aplikację kliknij <Icon
                                            src={data.iosShareIcon.childImageSharp.fluid.src}/>,
                                            a następnie <b>'Dodaj do ekranu początkowego'</b>.
                                        </div>
                                    </PopupInstruction>
                                    }
                                    {this.browser !== 'iOS' &&
                                    <PopupInstruction>
                                        <div>
                                            Aby zainstalować aplikację kliknij <Icon
                                            src={data.menuIcon.childImageSharp.fluid.src}/> na pasku przeglądarki,
                                            a następnie <b>'Dodaj do ekranu głównego'</b>.
                                        </div>
                                    </PopupInstruction>
                                    }
                                    <PopupButtons>
                                        <Button onClick={this.handleClosePopup} title='Rozumiem'/>
                                    </PopupButtons>
                                    <ClosePopupLink margin={3} align='left' small={true}
                                                    onClick={this.handleCloseAndForget}>
                                        Nie pokazuj tego więcej
                                    </ClosePopupLink>
                                </PopupContent>
                            </PopupWrapper>
                        );
                    } else {
                        return null;
                    }
                }}
            />
        );
    }
}

export default InstallPopup;
