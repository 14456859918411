import React from "react"
import MainTemplate from "templates/Main/MainTemplate";
import Login from "components/Pages/Login";

const LoginPage = (props) => (
    <MainTemplate {...props}>
        <Login {...props}/>
    </MainTemplate>
);

export default LoginPage;