import React from "react";
import styled from "styled-components";

import { translate } from "services/translate"

import IconGoogle from "assets/images/svg/icons8-google.svg"
import IconFacebook from "assets/images/svg/icons8-facebook.svg"

import Button from "components/Button"

const GoogleButton = styled(Button)`
    width: calc(100% - 40px);
    font-weight: bold;
    background: white;
    color: black;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
    font-size: 1.1rem;
    line-height: 1.1;
    
    svg {
      width: 2em;
      height: 2em;
      margin: 0 0.5em 0 0;
    }
`

const FacebookButton = styled(GoogleButton)`
    background: blue;
    color: white;
`

const ExternalAuth = ({onLoadFunc, register}) => {
    const handleGoogleLogin = () => {
        const firebase = require("firebase")
        let provider = new firebase.auth.GoogleAuthProvider()

        if (onLoadFunc && typeof onLoadFunc === "function") {
            onLoadFunc({
                loading: true,
            });
        }

        firebase.auth().signInWithPopup(provider).then(result => {
            let loginMethod = false

            if (result.additionalUserInfo && result.additionalUserInfo.isNewUser) {
                loginMethod = "?firstLoginGoogle"
            }

            sessionStorage.setItem("firstUser", loginMethod)

            if (onLoadFunc && typeof onLoadFunc === "function") {
                onLoadFunc({
                    error: {},
                    loading: false,
                });
            }

        }).catch(function(error) {
            if (onLoadFunc && typeof onLoadFunc === "function") {
                onLoadFunc({
                    error: {
                        code: error.code,
                        message: translate(error.code),
                    },
                    loading: false,
                });
            }
        })
    }

    const handleFacebookLogin = () => {
        const firebase = require("firebase")
        let provider = new firebase.auth.FacebookAuthProvider()

        if (onLoadFunc && typeof onLoadFunc === "function") {
            onLoadFunc({
                loading: true,
            });
        }

        firebase.auth().signInWithPopup(provider).then(function(result) {

            let loginMethod = false

            if (result.additionalUserInfo && result.additionalUserInfo.isNewUser) {
                loginMethod = "?firstLoginFB"
            }

            sessionStorage.setItem("firstUser", loginMethod)

            if (onLoadFunc && typeof onLoadFunc === "function") {
                onLoadFunc({
                    error: {},
                    loading: false,
                });
            }
        }).catch(function(error) {
            if (onLoadFunc && typeof onLoadFunc === "function") {
                onLoadFunc({
                    error: {
                        code: error.code,
                        message: translate(error.code),
                    },
                    loading: false,
                });
            }
        })
    };

    return (
        <>
            <FacebookButton onClick={handleFacebookLogin}>
                <IconFacebook/>
                {register ? "Zarejestruj" : "Zaloguj"} się za pomocą Facebook
            </FacebookButton>
            <GoogleButton onClick={handleGoogleLogin}>
                <IconGoogle/>
                {register ? "Zarejestruj" : "Zaloguj"} się za pomocą Google
            </GoogleButton>
        </>

    )
};

export default ExternalAuth;